import axios from "axios";
import { ElNotification, ElMessageBox, ElMessage, ElLoading } from "element-plus";
// import { useStore } from "vuex";
import store from "@/store";
import dialog from "@/store/modules/dialog"
import user from "@/store/modules/user"

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/pub-api",
  // baseURL: "http://127.0.0.1:8081",
  // baseURL: "http://45.136.14.26/pub-api",
  // baseURL: baseURL,
  // 超时
  timeout: 30000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;
    if (!isToken && store.state.user.token) {
      config.headers["Authorization"] = "Bearer " + store.state.user.token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);

let isMessageBoxShown = false;

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code || 200;
    if (res.request.responseType === "blob" || res.request.responseType === "arraybuffer") {
      return res.data;
    }

    if (code === 401) {
      console.log(isMessageBoxShown)
      console.log(!dialog.state.loginDialogVisible)
      console.log(!user.getters.isLogIn())

      if (!isMessageBoxShown && !dialog.state.loginDialogVisible && !user.getters.isLogIn()) {
        isMessageBoxShown = true;
        ElMessageBox.confirm(
          "Your session has expired. You can stay on this page or log in again.",
          "System Prompt",
          {
            confirmButtonText: "Login again",
            cancelButtonText: "Stay Without Login",
            type: "warning",
          }
        )
          .then(() => {
            store.dispatch("dialog/toggleLoginDialog", { visible: true });
            store.dispatch("user/logout");
            isMessageBoxShown = false;
          })
          .catch(() => {
            // store.dispatch("user/logout");
            isMessageBoxShown = false;
          });
      }
      return Promise.reject("Invalid session or session has expired, please log in again.");
    } else if (code === 500) {
      console.error(res.data.msg)
      ElMessage({ message: "Server Internal Error", type: "error" });
      return Promise.reject(new Error(res.data.msg));
    } else if (code === 601) {
      console.error(res.data.msg)
      ElMessage({ message: "Server Internal Error", type: "warning" });
      return Promise.reject(new Error(res.data.msg));
    } else if (code !== 200 && code !== 409) {
      ElNotification.error({ title: res.data.msg });
      return Promise.reject("error");
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message === "Network Error") {
      message = "Backend interface connection error";
    } else if (message.includes("timeout")) {
      message = "System interface request timeout";
    } else if (message.includes("Request failed with status code")) {
      message = "System interface " + message.substr(message.length - 3) + " error";
    }
    ElMessage({ message: message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

export default service;
