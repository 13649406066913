<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { getCodeEmail, getCodeImg, registerEmail, checkUsernameUnique, checkEmailUnique } from "@/domain/users/login/api/login";
import countryCodeJSON from "./country_code.json";
import CartController from "@/domain/cart/CartController";
import { useLocalObservable } from "mobx-vue-lite";
import { ElMessage } from "element-plus";
import EmailCaptchaForm from "@/domain/users/login/type/EmailCaptchaForm";

const props = defineProps({
  language: {
    type: String, // 指定类型
    default: "EN", // 默认值
  },
});

// 定义常量
const USERNAME_MIN_LENGTH = 5;
const USERNAME_MAX_LENGTH = 20;
const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 20;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^[0-9]{6,15}$/;

const { locale } = useI18n();
const store = useStore();

const cartCtrl = CartController;

const langDropdown = ref("中文");
const img = ref("");
const uuid = ref("");
const loginForm = ref({
  email: "",
  password: "",
  code: "",
});
const isSignUp = ref(false);
const isButtonDisabled = ref(false); // 控制按钮是否禁用
const countdown = ref(60); // 倒计时秒数

const usernameStatusMessage = ref(""); // 用于显示检查结果的消息
const usernameStatusColor = ref("black"); // 用于控制消息的颜色
const emailStatusMessage = ref(""); // 用于显示检查结果的消息
const emailStatusColor = ref("black"); // 用于控制消息的颜色

const changeLang = (lang: string, str: string) => {
  locale.value = lang;
  langDropdown.value = str;
};

const checkUsername = async () => {
  const username = registerForm.value.username;
  if (!username) {
    usernameStatusMessage.value = "Username cannot be empty";
    usernameStatusColor.value = "red";
    return;
  }

  usernameStatusMessage.value = "Username checking...";
  usernameStatusColor.value = "green";
  const res = await checkUsernameUnique({userName: username});
  console.log("username checked")
  console.log(res)
  if (res.code == 200) {
    usernameStatusMessage.value = "Username is available";
    usernameStatusColor.value = "green";
  } else {
    usernameStatusMessage.value = res.msg;
    usernameStatusColor.value = "red";
  }
}

const checkEmail = async () => {
  const email = registerForm.value.email;
  if (!email) {
    usernameStatusMessage.value = "Email cannot be empty";
    usernameStatusColor.value = "red";
    return;
  }

  emailStatusMessage.value = "Email checking...";
  emailStatusColor.value = "green";
  const res = await checkEmailUnique({email: email});
  if (res.code == 200) {
    emailStatusMessage.value = "Email is available";
    emailStatusColor.value = "green";
  } else {
    emailStatusMessage.value = res.msg;
    emailStatusColor.value = "red";
  }
}

const updateCodeImg = async () => {
  const res = await getCodeImg();
  img.value = "data:image/jpeg;base64," + res.img;
  uuid.value = res.uuid;
};

const sendEmailCode = async () => {
  if (isSignUp.value && (registerForm.value.email == null || registerForm.value.email == "")
  || !isSignUp.value && (loginForm.value.email == null || loginForm.value.email == "")) {
      ElMessage.error("email is required");
      return
  }

  if (isButtonDisabled.value) return; // 如果按钮被禁用，禁止再次点击

  // 开始倒计时
  isButtonDisabled.value = true;
  countdown.value = 60;

  try {
    const res = await getCodeEmail({
        email: isSignUp.value ? registerForm.value.email : loginForm.value.email,
        type: isSignUp.value ? "register" : "login",
      }
    );
    console.log("send email finished")
    console.log(res)
    if (res.code === 200) {
      ElMessage.info("The verification email was sent successfully!");
    } else {
      ElMessage.error(res.message);
    }
  } catch (e) {
    isButtonDisabled.value = false;
  }


  // 开始倒计时
  const interval = setInterval(() => {
    countdown.value--;

    // 倒计时结束
    if (countdown.value <= 0) {
      clearInterval(interval);
      isButtonDisabled.value = false; // 启用按钮
    }
  }, 1000);
};

const sendEmailButtonText = computed(() => {
  if (isButtonDisabled.value) {
    return `${countdown.value} s`
  } else {
    return "Send Email Verification Code"
  }
})

const onShowLogin = async () => {
  store.commit("dialog/setLoginDialogVisible", { visible: true });
};

const onLogin = async () => {
  try {
    await store.dispatch("user/login", {
      username: loginForm.value.email,
      password: loginForm.value.password,
      code: loginForm.value.code
    });
    store.commit("dialog/setLoginDialogVisible", { visible: false });
    await store.dispatch("user/queryBalance");
    await cartCtrl.queryCartList();
  } catch (e) {
    await updateCodeImg();
  }
};

const onLogout = () => {
  store.dispatch("user/logout");
};

watch(
  () => store.state.dialog.loginDialogVisible,
  (value, oldValue) => {
    if (value) {
      updateCodeImg();
    }
  },
);

/* 菜单路由高亮 */
const route = useRoute();

const activeMenu = computed(() => {
  const { meta, path } = route;
  return "/" + path.split("/")?.[1];
});

const router = useRouter();
const routeMenu = router
  .getRoutes()
  .filter((item) => item.path !== "/" && item.path.split("/").length === 2)
  .filter((item) => item.meta.hidden !== true);

const countryCode = computed(() => {
  if (locale.value === "zh-CN") {
    return countryCodeJSON.list.map((item) => ({ label: item.Name, value: item.IOS2 }));
  } else {
    return countryCodeJSON.list.map((item) => ({ label: item.EnName, value: item.IOS2 }));
  }
});

const registerForm = ref({
  username: "",
  password: "",
  confirmPassword: "",
  email: "",
  countryCode: "",
  areaCode: "",
  phoneNumber: "",
  code: "",
  uuid: ""
});

// 国家/地区选项
const countryCodeOptions = ref([
  { label: 'Australia', value: 'AU' },
  { label: 'China', value: 'CN' },
  { label: 'Japan', value: 'JP' },
  { label: 'United States', value: 'US' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Canada', value: 'CA' },
  { label: 'Germany', value: 'DE' },
  { label: 'France', value: 'FR' },
  { label: 'South Korea', value: 'KR' },
  { label: 'India', value: 'IN' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Russia', value: 'RU' },
  { label: 'Italy', value: 'IT' },
  { label: 'Spain', value: 'ES' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Norway', value: 'NO' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Finland', value: 'FI' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Austria', value: 'AT' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Israel', value: 'IL' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Egypt', value: 'EG' },
  { label: 'Greece', value: 'GR' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Poland', value: 'PL' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Romania', value: 'RO' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Bangladesh', value: 'BD' },
]);

// 区号选项
const generateAreaCodes = () => {
  const areaCodeMapping = [
    { country: 'Australia', code: '61' },
    { country: 'China', code: '86' },
    { country: 'Japan', code: '81' },
    { country: 'United States', code: '1' },
    { country: 'United Kingdom', code: '44' },
    { country: 'Canada', code: '1' },
    { country: 'Germany', code: '49' },
    { country: 'France', code: '33' },
    { country: 'South Korea', code: '82' },
    { country: 'India', code: '91' },
    { country: 'Mexico', code: '52' },
    { country: 'Brazil', code: '55' },
    { country: 'Russia', code: '7' },
    { country: 'Italy', code: '39' },
    { country: 'Spain', code: '34' },
    { country: 'Netherlands', code: '31' },
    { country: 'Switzerland', code: '41' },
    { country: 'Sweden', code: '46' },
    { country: 'Norway', code: '47' },
    { country: 'Denmark', code: '45' },
    { country: 'Finland', code: '358' },
    { country: 'Belgium', code: '32' },
    { country: 'Austria', code: '43' },
    { country: 'New Zealand', code: '64' },
    { country: 'South Africa', code: '27' },
    { country: 'Israel', code: '972' },
    { country: 'Ireland', code: '353' },
    { country: 'Singapore', code: '65' },
    { country: 'Malaysia', code: '60' },
    { country: 'Indonesia', code: '62' },
    { country: 'Thailand', code: '66' },
    { country: 'Vietnam', code: '84' },
    { country: 'Philippines', code: '63' },
    { country: 'Saudi Arabia', code: '966' },
    { country: 'United Arab Emirates', code: '971' },
    { country: 'Turkey', code: '90' },
    { country: 'Argentina', code: '54' },
    { country: 'Chile', code: '56' },
    { country: 'Colombia', code: '57' },
    { country: 'Egypt', code: '20' },
    { country: 'Greece', code: '30' },
    { country: 'Portugal', code: '351' },
    { country: 'Poland', code: '48' },
    { country: 'Czech Republic', code: '420' },
    { country: 'Hungary', code: '36' },
    { country: 'Romania', code: '40' },
    { country: 'Ukraine', code: '380' },
    { country: 'Pakistan', code: '92' },
    { country: 'Bangladesh', code: '880' },
  ];

  return areaCodeMapping.map(({ country, code }) => ({
    label: `${country} (+${code})`,
    value: code,
  }));
};

const areaCodes = ref(generateAreaCodes());


// 表单验证
const validateForm = () => {
  const { username, password, confirmPassword, email, phoneNumber } = registerForm.value;
  let msg = '';

  if (!username) {
    msg = 'Username should not be empty';
  } else if (!password) {
    msg = 'User password should not be empty';
  } else if (username.length < USERNAME_MIN_LENGTH || username.length > USERNAME_MAX_LENGTH) {
    msg = `Account length should be between ${USERNAME_MIN_LENGTH} and ${USERNAME_MAX_LENGTH}`;
  } else if (password.length < PASSWORD_MIN_LENGTH || password.length > PASSWORD_MAX_LENGTH) {
    msg = `Account password should be between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH}`;
  } else if (!email) {
    msg = 'Email should not be empty';
  } else if (!email.match(EMAIL_REGEX)) {
    msg = 'Email address is not valid';
  } else if (!phoneNumber) {
    msg = 'Phone number should not be empty';
  } else if (!(phoneNumber).match(PHONE_REGEX)) {
    msg = 'Phone number is not valid';
  }

  if (msg) {
    ElMessage.error(msg);
    return false;
  }

  if (password !== confirmPassword) {
    ElMessage.error('Passwords do not match');
    return false;
  }

  return true;
};

// 注册按钮点击处理函数
const onSignUp = async () => {
  if (validateForm()) {
    registerForm.value.uuid = uuid.value;
    registerForm.value.phoneNumber = "+" + registerForm.value.areaCode + "|" + registerForm.value.phoneNumber;
    // 处理注册逻辑
    console.log("Sign up clicked", registerForm.value);
    try {
      const response = await registerEmail(registerForm.value);
      console.log("=====!");
      console.log(response);
      if (response.code === 200) {
        ElMessage.success('Registration successful!');
        isSignUp.value = false;
        store.commit("dialog/setLoginDialogVisible", { visible: false });
      } else {
        ElMessage.error(`Registration failed: ${response.data.message}`);
      }
    } catch (error) {
      ElMessage.error(`Registration failed: ${error.response.data.message}`);
    }
  }
};
</script>

<template>
  <div style="width: 100%">
    <el-menu
      :default-active="activeMenu"
      class="nav-bar py-3"
      mode="horizontal"
      background-color="#fefefe"
      text-color="#707070"
      active-text-color="#000000"
      :ellipsis="false"
    >
      <!--导航左侧-->
      <div class="flex flex-row justify-center items-center ml-10">
        <template v-for="(item, index) in routeMenu" :key="item.path">
          <el-menu-item :index="item.path" @click="$router.push(item.path)" class="mx-4 menu-item-hover-shadow">
            <span class="text-lg" :class="{ 'active': $route.path === item.path }">{{ $t(item.meta.title) }}</span>
          </el-menu-item>

          <!-- 添加分隔符，除最后一个元素外 -->
          <span v-if="index < routeMenu.length - 1" class="separator" style="color: #707070">|</span>
        </template>
      </div>

      <el-menu-item
        index="3"
        @click="$router.push('/users/all')"
        v-show="$store.state.userInfo.userType === 0"
      >
        {{ $t("nav.user-management") }}
      </el-menu-item>
      <el-menu-item
        index="5"
        @click="$router.push('/membership')"
        v-show="$store.state.userInfo.userType === 1 || $store.state.userInfo.userType === 2"
      >
        {{ $t("nav.membership") }}
      </el-menu-item>

      <span class="flex-grow" style="flex-grow: 1" />

      <!--导航右侧-->
      <div
        class="h-full mr-5 flex flex-row justify-center items-center"
        @click="onShowLogin"
        v-show="$store.getters['user/isLogIn'] === false"
      >
        <button class="tag-button text-white font-bold text-lg" >
          Login
        </button>
      </div>
<!--      <el-dropdown>-->
<!--        <div class="el-menu-item">{{ langDropdown }}</div>-->
<!--        <template #dropdown>-->
<!--          <el-dropdown-menu>-->
<!--            <el-dropdown-item @click="changeLang('en-US', 'English')">English</el-dropdown-item>-->
<!--            <el-dropdown-item @click="changeLang('zh-CN', '中文')">中文</el-dropdown-item>-->
<!--          </el-dropdown-menu>-->
<!--        </template>-->
<!--      </el-dropdown>-->
      <div></div>
<!--      TODO: 购物车按钮-->
<!--      <div class="el-menu-item" @click="$router.push('/shopping-cart')">-->
<!--        <el-badge :value="cartCtrl.count" style="transform: translateY(10px)">-->
<!--          <el-icon size="22" style="color: #ffffff; transform: translateY(-10px)">-->
<!--            <ShoppingCart />-->
<!--          </el-icon>-->
<!--        </el-badge>-->
<!--      </div>-->
    </el-menu>

    <!--用户头像-->
    <div
      v-show="$store.getters['user/isLogIn'] === true"
      class="personal-info"
      style="position: absolute; right: 140px; top: 0"
    >
      <div style="zoom: 300%; float: right" class="avatar">
        <el-icon
          style="
            margin: 0 auto;
            position: absolute;
            z-index: 2;
            background-color: #a6a7ab;
            border-radius: 100%;
            box-shadow: #202124 1px 1px 1px 1px;
            padding: 1px;
          "
        >
          <Avatar />
        </el-icon>
      </div>

      <div
        class="avatar-panel"
        style="
          background-color: white;
          position: relative;
          right: -20px;
          top: 20px;
          z-index: 1000;
          border-radius: 5%;
          border: thin solid #e3e5e7;
        "
      >
        <div style="display: flex; flex-direction: column; text-align: center">
<!--          <span style="left: 30%; font-size: 20px; margin-top: 3%">-->
<!--            {{ $store.state.user.name }}-->
<!--          </span>-->
<!--          <div style="display: flex; flex-direction: row">-->
<!--            <div class="flex-grow" style="flex-grow: 1" />-->
<!--            <div-->
<!--              style="-->
<!--                background: linear-gradient(to right bottom, #c5cad6, #41444e, #c5cad6);-->
<!--                border-radius: 5px;-->
<!--                color: #f3f7fb;-->
<!--                padding: 2px 8px;-->
<!--              "-->
<!--            >-->
<!--              Platinum Member-->
<!--            </div>-->
<!--            <div class="flex-grow" style="flex-grow: 1" />-->
<!--          </div>-->
<!--          <span style="color: #61666d; padding: 8px">-->
<!--            {{ $t("nav.point-balance") }}:-->
<!--            <span style="color: #202124">{{ $store.state.user.balance }} P</span><br />-->
<!--          </span>-->
          <el-menu
            mode="vertical"
            background-color="#565760"
            text-color="#fff"
            active-text-color="#ffd04b"
            style="border-radius: 5%"
          >
<!--            <el-menu-item-->
<!--              style="border-radius: 20%; z-index: 2"-->
<!--              index="1"-->
<!--              @click="$router.push('/my-account')"-->
<!--            >-->
<!--              {{ $t("nav.my-account") }}-->
<!--            </el-menu-item>-->
            <el-menu-item
              style="border-radius: 20%; z-index: 2"
              index="2"
              @click="$router.push('/favorite-list')"
            >
              My Collections
            </el-menu-item>
            <el-menu-item style="border-radius: 20%; z-index: 2" index="3" @click="$router.push('/users/orders')">
              {{ $t("nav.my-order") }}
            </el-menu-item>
<!--            <el-menu-item style="border-radius: 20%; z-index: 2" index="4">-->
<!--              {{ $t("nav.transaction-progress") }}-->
<!--            </el-menu-item>-->
<!--            <el-menu-item style="border-radius: 20%; z-index: 2" index="5">-->
<!--              {{ $t("nav.after-sales-service") }}-->
<!--            </el-menu-item>-->
<!--            <el-menu-item style="border-radius: 20%; z-index: 2" index="6" @click="onLogout">-->
<!--              {{ $t("nav.logout") }}-->
<!--            </el-menu-item>-->
          </el-menu>
        </div>
      </div>
    </div>

    <div>
      <el-dialog
        v-model="$store.state.dialog.loginDialogVisible"
        style="width: 800px; display: flex; flex-direction: column; text-align: center"
      >
        <!--登录对话框-->
        <el-row v-if="isSignUp === false">
          <el-col :offset="4" :span="16">
            <div style="font-size: 15px">{{ $t("nav.login-prompt") }}</div>
            <div style="text-align: left; padding-top: 5px; font-weight: bold">
              Email
            </div>
            <input
              type="text"
              style="width: 100%; height: 20px"
              placeholder="name@host.com"
              v-model="loginForm.email"
            />
            <div style="text-align: left; padding-top: 5px; font-weight: bold">
              {{ $t("nav.password") }}
            </div>
            <input
              type="password"
              style="width: 100%; height: 20px"
              :placeholder="$t('nav.password')"
              v-model="loginForm.password"
            />
            <div style="text-align: left; padding-top: 5px; font-weight: bold">Verification Code</div>
            <div style="display: flex; gap: 5px; align-items: center">
              <input type="text" style="width: 220px; height: 20px" v-model="loginForm.code" />
<!--              <el-image style="width: 80px; height: 30px" :src="img" />-->
              <el-button
                type="info"
                :disabled="isButtonDisabled"
                style="min-width: 100px;"
                @click="sendEmailCode"
              >
                {{sendEmailButtonText}}
              </el-button>
            </div>
            <div style="text-align: left; padding-top: 5px; margin-bottom: 15px">
              <a href="">{{ $t("nav.forget-password") }}</a>
            </div>
            <div>
              <el-button style="width: 100%; margin-top: 7px" color="red" @click="onLogin()">
                {{ $t("nav.sign-in") }}
              </el-button>
            </div>
            <span>
              {{ $t("nav.need-account") }}
              <a href="#" @click="isSignUp = true">{{ $t("nav.sign-up") }}</a>
            </span>
          </el-col>
        </el-row>

        <!--注册对话框-->
        <el-row v-show="isSignUp">
          <el-col :offset="4" :span="16">
            <div style="font-size: 15px">Please register with the following information:</div>

            <div style="text-align: left; padding-top: 15px; font-weight: bold">Username:</div>
            <input
              v-model="registerForm.username"
              type="text"
              style="width: 100%; height: 20px"
              placeholder="Enter your username"
              @blur="checkUsername"
            />
            <!-- 显示用户名检查结果 -->
            <div v-if="usernameStatusMessage" :style="{ color: usernameStatusColor }">
              {{ usernameStatusMessage }}
            </div>

            <div style="text-align: left; padding-top: 5px; font-weight: bold">Email:</div>
            <input
              v-model="registerForm.email"
              type="email"
              style="width: 100%; height: 20px"
              placeholder="name@host.com"
              @blur="checkEmail"
            />

            <!-- 显示邮箱检查结果 -->
            <div v-if="emailStatusMessage" :style="{ color: emailStatusColor }">
              {{ emailStatusMessage }}
            </div>

            <div style="text-align: left; padding-top: 5px; font-weight: bold">Password:</div>
            <input
              v-model="registerForm.password"
              type="password"
              style="width: 100%; height: 20px"
              placeholder="Enter your password"
            />

            <div style="text-align: left; padding-top: 5px; font-weight: bold">Confirm Password:</div>
            <input
              v-model="registerForm.confirmPassword"
              type="password"
              style="width: 100%; height: 20px"
              placeholder="Re-enter your password"
            />



<!--            <div style="text-align: left; padding-top: 5px; font-weight: bold">Country / Region:</div>-->
<!--            <el-select-->
<!--              v-model="registerForm.countryCode"-->
<!--              size="small"-->
<!--              style="width: 100%"-->
<!--              placeholder="Select your country/region"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="item in countryCodeOptions"-->
<!--                :label="item.label"-->
<!--                :value="item.value"-->
<!--                :key="item.value"-->
<!--              />-->
<!--            </el-select>-->

            <div style="text-align: left; padding-top: 5px; font-weight: bold">Phone Number:</div>
            <div style="display: flex;">
              <el-select
                v-model="registerForm.areaCode"
                size="small"
                style="width: 50%;"
                placeholder="Area code"
              >
                <el-option
                  v-for="item in areaCodes"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
              <input
                v-model="registerForm.phoneNumber"
                type="text"
                style="width: 70%; height: 20px; margin-left: 5px;"
                placeholder="Phone number"
              />
            </div>

            <div style="text-align: left; padding-top: 5px; font-weight: bold">Verification Code</div>
            <div style="display: flex; gap: 5px; align-items: center">
              <input type="text" style="width: 220px; height: 20px" v-model="registerForm.code" />
<!--              验证码图片-->
<!--              <el-image style="width: 80px; height: 30px" :src="img" />-->
              <el-button
                type="info"
                :disabled="isButtonDisabled"
                style="min-width: 100px;"
                @click="sendEmailCode"
              >
                {{sendEmailButtonText}}
              </el-button>
            </div>

            <div>
              <el-button type="primary" style="width: 100%; margin-top: 12px" @click="onSignUp">
                Sign Up
              </el-button>
            </div>

            <span>
              Already have an account?
              <a href="#" @click="isSignUp = false">Sign In</a>
            </span>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped>
.nav-bar {
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 5px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 6%;
}

.avatar-panel {
  z-index: 10;
  display: none;
}

.personal-info:hover .avatar {
  zoom: 200%;
}

.personal-info:hover .avatar-panel {
  display: block;
}

.tag-button {
  padding: 0.6rem 1rem 0.6rem 2rem;
  background-color: black;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  position: relative;
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 30% 100%, 0 50%);
}

.tag-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid black;
}

.menu-item-hover-shadow {
  border-radius: 10px; /* 为导航项添加圆角 */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.menu-item-hover-shadow:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  transform: translateY(-2px); /* 悬停时轻微上移，使悬停效果更加生动，可选 */
}
</style>
